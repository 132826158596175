<div>
    <section class="no-pages-wrapper font-weight-300">
        <div class="row">
            <div class="text-center col-sm-28 center-block">
                <img class="img-responsive mx-auto"
                     src="/images/advanced-analytics/advanced-analytics-illustration.png"
                     alt="{{''}}">
                <h1 class="line-height-32 text-grey-1 font-weight-400 font-size-24 line-height-32 mt-6 mb-1">
                    <span translate>Advanced Analytics</span>
                </h1>
                <p><span class="mr-1" translate>Powered by</span> <img class="img-responsive mx-auto piwik-logo"
                    src="/images/advanced-analytics/piwik-pro.png"
                    alt="{{'Piwik PRO'}}"></p>
                <p class="line-height-16 text-grey-1 mt-4 mb-4" translate>Monsido has partnered with Piwik PRO to bring you the market-leading solution in the Analytics space.</p>
                <p class="my-4" translate>Piwik PRO is a digital analytics platform that measures, analyzes and improves the user experience at each step of the user journey, with an intuitive interface and reporting, extensive data integration capabilities, and easy privacy compliance settings.</p>
                <p>
                    <a target="_blank"
                        aria-describedby="linkOpensInNewTab"
                        rel="noopener"
                        href="https://help.monsido.com/en/articles/9780114-advanced-analytics-powered-by-piwik-pro">Read more about Monsido and Piwik PRO’s partnership.</a>
                </p>
            </div>
            <div class="d-flex justify-content-center col-sm-28 center-block mt-6">
                <div class="col-22 center-widget">
                    <h3 translate>New user?</h3>
                    <p translate>Sign up for free Piwik PRO account.</p>
                </div>
                <div class="col-22 center-widget">
                    <h3 translate>Already have a Piwik PRO account?</h3>
                    <p translate>Connect your existing Piwik PRO account to Monsido by pasting your Piwik PRO account domain below.</p>
                </div>
            </div>
            <div class="d-flex justify-content-center col-sm-28 center-block mb-4">
                <div class="col-22 center-widget">
                    <a href="https://campaign.piwik.pro/core-monsido/"
                        target="_blank"
                        rel="noopener"
                        aria-describedby="linkOpensInNewTab"
                        class="btn btn-border-radius btn-secondary ml-4"><span translate>Sign up with Piwik PRO</span><mon-icon class="icon ml-1" [monIconDirect]="'faUpRightFromSquare'"></mon-icon></a>
                </div>
                <div class="col-22 center-widget">
                    <div class="text-nowrap text-center">
                        <form name="formPiwikConnect"
                            #piwikConnectForm="ngForm"
                            class="form-horizontal"
                            (ngSubmit)="connectAccount(piwikConnectForm)">

                            <input type="text"
                                class="form-control your-account-conect-input mb-1"
                                name="piwik-url"
                                #piwikAccountUrlModel="ngModel"
                                [(ngModel)]="piwikAccountUrl"
                                monPiwikValidation
                                placeholder="{{'youraccount.piwik.pro'}}">
                            <button type="submit"
                                [attr.aria-disabled]="piwikAccountUrlModel.invalid"
                                monEventTrigger="actionTrigger"
                                [monEventParams]="{action: 'advancedanalytics-connectaccount-btn'}"
                                [ngClass]="{
                                    'btn btn-border-radius btn-secondary ml-1': true,
                                    'disabled': piwikAccountUrlModel.invalid || !piwikAccountUrl || savingUrlInProgress,
                                }" translate>
                                Connect account
                            </button>
                            <mon-form-field-errors-ac
                                    [offset]="-8"
                                    patternMsg="{{'The URL is incorrect. Please use the pattern youraccount.piwik.pro' | translate}}"
                                    [formElementControl]="piwikAccountUrlModel.control"
                                    ></mon-form-field-errors-ac>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>
